<template>
  <div>
    <Pane />
    <div class="container">
      <a-descriptions bordered :column="2">
        <a-descriptions-item>
          <div slot="label" class="center">会议名称</div>
          <div class="">
            {{ detail.meetName }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">督办事项</div>
          <div class="">
            {{ detail.name }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">督办类型</div>
          <div class="">
            {{ type.name ? type.name : "--" }} /
            {{ subType }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">执行人</div>
          <div class="">
            {{ user.deptUniqueName }} -
            {{ user.name }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">办结时限</div>
          <div class="">
            {{ detail.originalDate }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">办结要求</div>
          <div class="">
            {{ detail.remarks }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">动态追踪</div>
          <div class="left">
            <a-tag
              v-for="(item, index) in tracksList"
              :key="index"
              style="margin-right: 8px"
            >
              {{ item.trackDate }}
            </a-tag>
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">相关附件</div>
          <div class="left">
            <a
              v-for="item in fileList"
              :key="item.id"
              target="_blank"
              style="margin-right: 8px"
              download
              :href="item.completePath"
              >{{ item.name }}</a
            >
          </div>
        </a-descriptions-item>
      </a-descriptions>

      <div class="center" style="margin-top: 80px">
        <a-button @click="close">关闭</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchDetail } from "@/api/supervise";
import { mapGetters } from "vuex";

export default {
  mixins: [watermark],

  data() {
    return {
      detail: {},
      user: {}, // 选择的执行人
      tracksList: [], // 动态时间节点列表
      fileList: [], // 上传的文件
      loading: false,
      id: null, // 正在编辑的 ID
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    // 督办类型
    typeList() {
      return this.findDataDict("dblx");
    },

    type() {
      const obj = this.typeList.find((item) => item.value === this.detail.type);
      return obj ? obj : {};
    },
    subType() {
      if (this.type && Array.isArray(this.type.children)) {
        const obj = this.type.children.find(
          (item) => item.value === this.detail.subType
        );
        return obj ? obj.name : "--";
      } else {
        return "--";
      }
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({
        id,
      }).then((res) => {
        this.detail = {
          ...res,
        };

        this.user = {
          name: res.assigneeName,
          deptUniqueName: res.assigneeDeptName,
        };

        if (Array.isArray(res.tracksList)) {
          this.tracksList = res.tracksList;
        }

        if (Array.isArray(res.fileList)) {
          this.fileList = res.fileList;
        }
      });
    }
  },

  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}
</style>
